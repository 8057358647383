import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
import HomeLoopSlider from "../components/sliders/home-slider/home-loop-slider"
import CommonSlider from "../components/sliders/common-slider/common-slider"
import RetailApplicationSlider from "../components/sliders/retail-application-slider/retail-application-slider"

import video from "../videos/file_example_MP4_480_1_5MG.mp4"
import VideoSlider from "../components/sliders/video-slider/video-slider"
import $ from "jquery"
import ApplicationSoftware from "../components/sliders/retail-application-slider/application-software-slider"

const Home = ({ data }) => {
  const vertiveImageData = getImage(data.vertivBg)
  const applicationBgData = getImage(data.applicationsBg)
  const homeAboutBgData = getImage(data.homeAboutBg)
  const Sap_Products = getImage(data.Sap_Products)

  const images = {
    vertivBg: convertToBgImage(vertiveImageData),
    applicationsBg: convertToBgImage(applicationBgData),
    homeAboutBg: convertToBgImage(homeAboutBgData),
    Sap_Products: convertToBgImage(Sap_Products),
  }

  const homeSliderImages = {
    mainScreen: data.mainScreen,
    mainScreen2: data.mainScreen2,
    mainScreen3: data.mainScreen3,
  }

  const sliderLogoImages = {
    Sap4hana: getImage(data.Sap4hana),
    sapAriba: getImage(data.sapAriba),
    customerEx: getImage(data.customerEx),
    Sap_su_fa: getImage(data.Sap_su_fa),
    sap_bussi_one: getImage(data.sap_bussi_one),
    sap_bBD: getImage(data.sap_bBD),
    sap_an_cloud: getImage(data.sap_an_cloud),
    riseWithSap: getImage(data.riseWithSap),
  }

  const retailLogos = {
    adobe: getImage(data.adobe),
    corel_d: getImage(data.corel_d),
    team_viewer: getImage(data.team_viewer),
    arcserve: getImage(data.arcserve),
    siemens: getImage(data.siemens),
    hcl: getImage(data.hcl),
    kapture: getImage(data.kapture),
    proaxia: getImage(data.proaxia),
    tristha: getImage(data.tristha),
    Freshworks: getImage(data.Freshworks),
    Diecentral: getImage(data.Diecentral),
  }

  const home_slider = [
    {
      index: 0,
      titles: ["Making Technology", "Simple for", "Every Business"],
      image: homeSliderImages.mainScreen,
      link: "/about",
    },
    {
      index: 1,
      titles: ["No. 01", "SAP Partner in Sri lanka"],
      image: homeSliderImages.mainScreen2,
      link: "/sap",
    },
    {
      index: 2,
      titles: ["A Vertiv Smart Solution Partner", "Like No Other"],
      image: homeSliderImages.mainScreen3,
      link: "products/digital-infrastructure",
    },
  ]

  const common_slider_1 = [
    {
      index: 0,
      img: sliderLogoImages.Sap4hana,
      link: "sap/#Sap4hana",
      title: "SAP S/4HANA",
      description:
        "SAP S/4HANA Cloud is a complete enterprise resource planning (ERP) system with built-in intelligent technologies, including AI, machine learning, and advanced analytics.",
    },
    {
      index: 1,
      img: sliderLogoImages.riseWithSap,
      link: "sap/#riseWithSap",
      title: "Rise with SAP S/4HANA",
      description:
        "RISE with SAP offers a holistic on-your-terms and on-your-timeline transformation to an intelligent enterprise.",
    },
    {
      index: 2,
      img: sliderLogoImages.sap_bBD,
      link: "sap/#sapBusinessByDesign",
      title: " SAP Business ByDesign (ByD)",
      description:
        "SAP Business ByDesign delivers pre-built processes for everything from finance and sales to product management and purchasing on a single, unified solution",
    },
    {
      index: 3,
      img: sliderLogoImages.sap_bussi_one,
      link: "sap/#sapBusinessOne",
      title: "SAP Business One (B1)",
      description:
        "A single, affordable solution to manage your entire small business – from accounting and financials, purchasing, inventory, sales and customer relationships to reporting and analytics.",
    },
    {
      index: 4,
      img: sliderLogoImages.Sap_su_fa,
      link: "sap/#sapSuccessFactors",
      title: "SAP SuccessFactors (HCM)",
      description:
        "Understand your customers and engage them with hyper-personalized experiences.",
    },
    {
      index: 5,
      img: sliderLogoImages.customerEx,
      link: "sap/#sapCustomerExperienceManagementSolution",
      title: "SAP Customer Experience Management Solution",
      description:
        "SAP Customer Experience Solution is a suite of solutions designed to improve customer experience and increase employee productivity.",
    },
    {
      index: 6,
      img: sliderLogoImages.sap_an_cloud,
      link: "sap/#sapAnalyticsCloud",
      title: "SAP Analytics Cloud ",
      description:
        "The SAP Analytics Cloud solution combines BI, augmented and predictive analytics, and planning capabilities into one cloud environment.",
    },
    {
      index: 7,
      img: sliderLogoImages.sapAriba,
      link: "sap/#sapIntelligentSpendManagement",
      title: "SAP Intelligent Spend Management",
      description:
        "Spend management solutions from SAP give you the ability to make fast, intelligent decisions to help keep your business moving forward.",
    },
  ]

  const retail_slider = [
    {
      index: 0,
      link: "products/applications/#adobe",
      img: retailLogos.adobe,
      description:
        "Adobe’s Creative applications facilitate the full range of creative design, development and editing needs, across devices, while its Acrobat application software enables file management.",
    },
    {
      index: 1,
      link: "products/applications/#corelDraw",
      img: retailLogos.corel_d,
      description:
        "A vector based graphics software mainly used for marketing and advertising purposes, CorelDRAW is popular among professionals to help digitally create logos, brochures, etc.",
    },
    {
      index: 2,
      link: "products/applications/#teamViewer",
      img: retailLogos.team_viewer,
      description:
        "A remote support solution that enables access to computers or mobile devices located anywhere in the world, TeamViewer works with Windows, macOS, Android and iOS.",
    },
  ]

  const application_slider = [
    {
      index: 0,
      link: "products/applications/#arcserve",
      img: retailLogos.arcserve,
      description:
        "Arcserve is a comprehensive storage backup solution providing complete control and visibility from one centrally located management console for diversely scaled enterprises.",
    },
    {
      index: 1,
      link: "products/applications/#Diecentral",
      img: retailLogos.Diecentral,
      description:
        "DiCentral is a cloud based EDI solution that allows businesses to manage all of their fulfillment channels and software integrations on a unified cloud platform.",
    },
    {
      index: 2,
      link: "products/applications/#Freshworks",
      img: retailLogos.Freshworks,
      description:
        "Freshworks’ suite of best-in-class software products support customer experience and employee engagement across sales & marketing, IT service management and HR management functions.",
    },
    {
      index: 3,
      link: "products/applications/#HCL",
      img: retailLogos.hcl,
      description:
        "HCL’s Domino is a platform that powers enterprise apps and workflows, is mobile- and web-ready, and can be deployed anywhere - on premise, hybrid or any cloud.",
    },
    {
      index: 4,
      link: "products/applications/#Kapture",
      img: retailLogos.kapture,
      description:
        "Kapture CRM is an enterprise-grade cloud CRM that utilizes machine learning to integrate your contact center, service center and field force in one comprehensive platform.",
    },
    {
      index: 5,
      link: "products/applications/#Proaxia",
      img: retailLogos.proaxia,
      description:
        "Proaxia offers a suite of innovative solutions that support sales. service processes and spare part logistics processes in the automotive, discrete manufacturing and industrial service sectors.",
    },
    {
      index: 6,
      link: "products/applications/#Siemens",
      img: retailLogos.siemens,
      description:
        "Siemens Advanced Planning software is a strategic decision-making tool designed to aid in planning. It impacts direction of production and supports feasible decision-making.",
    },
    {
      index: 7,
      link: "products/applications/#Tristha",
      img: retailLogos.tristha,
      description:
        "Tristha’s wide range of end-to-end automated testing solutions covers the entire application lifecycle ensuring holistic testing and world-class quality assurance.",
    },
  ]

  const common_slider_2 = [
    {
      index: 0,
      link: "/products/digital-infrastructure/#SmartCabinet",
      title: "Smart Cabinet",
      description:
        "The SmartCabinet by Vertiv enables Enterprise Class IT infrastructure via integrated enclosure, power, cooling, service and 360° visibility",
    },
    {
      index: 1,
      link: "/products/digital-infrastructure/#SmartRow",
      title: "Smart Row",
      description:
        "SmartRow from Vertiv is a unique, fully-integrated, room-neutral solution that allows you to deploy a complete micro data center in just weeks",
    },
    {
      index: 2,
      link: "/products/digital-infrastructure/#SmartSolutionsAndServerRooms",
      title: "Annual Maintenance Contracts - Smart Solutions & Server Rooms",
      description:
        "Tech Pacific’s post-warranty maintenance services ensure smooth operations of your critical digital infrastructure.",
    },
    {
      index: 3,
      link: "/products/digital-infrastructure/#GreenfieldAndBrownfieldConventionalDataCentersAndServerRooms",
      title: "Greenfield & Brownfield Conventional Data Centers & Server Rooms",
      description:
        "Tech Pacific provides comprehensive solutions to design new or expand existing server rooms and data centers.",
    },
    {
      index: 4,
      link: "/products/digital-infrastructure/#products",
      title: "Monitoring & Management of Existing Data Centers & Server Rooms",
      description:
        "Tech Pacific’s monitoring and management solutions enable administrators to manage the environmental conditions of data centers and server rooms.",
    },
  ]

  // function onVideoChange() {
  //   // player.stopVideo()
  //   $("iframe").attr("src", $("iframe").attr(""))
  // }

  const videos = [
    {
      index: 1,
      url: "https://youtu.be/nB__KxxcoTw",
    },
    {
      index: 2,
      url: "https://youtu.be/U4UFaAP17hE",
    },
    // {
    //   index: 3,
    //   url: "https://www.youtube.com/watch?v=buH9qCgK_Qk",
    // },
  ]
  return (
    <Layout>
      <Seo title="Home | Tech Pacific Lanka" />
      <section>
        <HomeLoopSlider slides={home_slider} />
      </section>
      <section>
        <BackgroundImage {...images.Sap_Products} className="mid-screen">
          <div className="mid-screen-content container">
            <span className="category">Digital Transformation</span>
            <span className="mid-screen-title">SAP Products</span>
            <span className="mid-screen-description">
              Tech Pacific offers a wide range of SAP solutions for Digital
              Transformation, targeting multiple facets of a business. An
              offering tailored through bespoke service, it integrates digital
              technology into all areas of a business, resulting in fundamental
              operational changes.{" "}
            </span>
            <Link to="/sap">
              <div className="learn-more">
                LEARN MORE
                <div className="icon">
                  <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                </div>
              </div>
            </Link>
          </div>
        </BackgroundImage>
      </section>
      <section className="d-flex align-items-center">
        <div className="swiper-button-prev1"></div>

        <div className="four-col-slider d-flex">
          <CommonSlider
            slides={common_slider_1}
            sliderPerView={4}
            class={"col-title"}
          />
        </div>
        <div className="swiper-button-next1"></div>
      </section>
      <section>
        <BackgroundImage
          Tag="div"
          {...images.vertivBg}
          preserveStackingContext
          className="mid-screen"
        >
          <div className="mid-screen-content container">
            <span className="category">Digital Transformation</span>
            <span className="mid-screen-title">Vertiv Smart Solutions</span>
            <span className="mid-screen-description">
              Digital Infrastructure is a collection of technologies that form
              the foundation that enables Digital Transformation. They can
              include Software Systems, Internet Backbone, Data Centers, Network
              Infrastructure, Cloud based Platforms, APIs and more.
            </span>
            <Link to="products/digital-infrastructure">
              <div className="learn-more">
                LEARN MORE
                <div className="icon">
                  <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                </div>
              </div>
            </Link>
          </div>
        </BackgroundImage>
      </section>
      <section className="d-flex align-items-center">
        <div className="swiper-button-prev1 2"></div>
        <div className="four-col-slider d-flex">
          <CommonSlider
            slides={common_slider_2}
            sliderPerView={4}
            class={"col-title title-orange"}
          />
        </div>
        <div className="swiper-button-next1 2"></div>
      </section>
      <section>
        <BackgroundImage
          Tag="div"
          preserveStackingContext
          {...images.applicationsBg}
          className="mid-screen"
        >
          <div className="mid-screen-content container">
            <span className="mid-screen-title">Applications</span>
            <span className="mid-screen-description">
              Tech Pacific offers a range of Applications including Adobe
              products, CorelDRAW, TeamViewer, Siemens and Arcserve. Often
              equipped with mobile apps and optional cloud services, they
              support developments across a range of devices, increasing digital
              efficiency.
            </span>
            <Link to="products/applications">
              <div className="learn-more">
                LEARN MORE
                <div className="icon">
                  <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                </div>
              </div>
            </Link>
          </div>
        </BackgroundImage>
      </section>

      <section>
        <div className="row m-0">
          <div className="col-md-6 bgTeal p-0">
            <div className=" title-left-application-slider">
              Retail Applications
            </div>
            <div className="d-flex align-items-center retail-slider">
              <div className="swiper-button-prev-retail"></div>
              <div className="four-col-slider retail-four-col left-retail d-flex">
                <RetailApplicationSlider slidesRetailApp={retail_slider} />
              </div>
              <div className="swiper-button-next-retail"></div>
            </div>
          </div>
          <div className="col-md-6 bgGreen p-0">
            <div className=" title-right-application-slider">
              Application Softwares
            </div>
            <div className="d-flex align-items-center application-slider">
              <div className="swiper-button-prev-software"></div>
              <div className="four-col-slider application-four-col d-flex">
                <ApplicationSoftware slidesRetailApp={application_slider} />
              </div>

              <div className="swiper-button-next-software"></div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <BackgroundImage
          Tag="div"
          preserveStackingContext
          {...images.homeAboutBg}
          className="mid-screen"
        >
          <div className="mid-screen-content container">
            <span className="mid-screen-title">About us</span>
            <span className="mid-screen-description">
              Tech Pacific is a testament to the pace of digital growth, having
              evolved with changing technology since its inception. Today it is
              a leading enterprise backed by a diverse workforce, specializing
              in Digital Transformation, Digital Infrastructure and
              Applications.
            </span>
            <Link to="/about">
              <div className="learn-more">
                LEARN MORE
                <div className="icon">
                  <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                </div>
              </div>
            </Link>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="home-success-stories-main">
          <div className="home-success-stories-main-content container">
            <div className="row ">
              <div className="col-md-6">
                <div className="home-success-stories d-flex flex-column">
                  <span className="home-success-stories-title">
                    Success Stories
                  </span>
                  <span className="home-success-stories-description">
                    Tech Pacific helps clients achieve success by understanding
                    the full scope of their needs and carefully matching them
                    with the right SAP software and mix of implementation
                    services as well as adaptations/customizations and training.
                    These success stories show how Tech Pacific can help you
                    leverage SAP solutions for successful Digital
                    Transformation.
                  </span>
                  <Link to="/success-stories">
                    <div className="see-more">
                      SEE MORE
                      <div className="icon">
                        <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center video-slider">
                  <VideoSlider videos={videos} />
                  <div className="swiper-button-next-video"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="get-in-touch d-flex align-items-center justify-content-center">
          <div className="get-in-touch-content text-center container">
            <p>Get In Touch With Our Experts</p>
            <Link to="/contact">
              <button className="btn btn-primary" type="button">
                Let's Get to Work
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    mainScreen: file(relativePath: { eq: "home/mainScreen.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    mainScreen2: file(relativePath: { eq: "home/mainScreen2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    mainScreen3: file(relativePath: { eq: "home/mainScreen3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    vertivBg: file(relativePath: { eq: "home/vertivBg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    applicationsBg: file(relativePath: { eq: "home/applicationsBg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    homeAboutBg: file(relativePath: { eq: "home/homeAboutBg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Sap4hana: file(relativePath: { eq: "home/slider-logos/Sap4hana.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sapAriba: file(relativePath: { eq: "home/slider-logos/sapAriba.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    customerEx: file(relativePath: { eq: "home/slider-logos/customerEx.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Sap_su_fa: file(relativePath: { eq: "home/slider-logos/Sap_su_fa.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sap_bussi_one: file(
      relativePath: { eq: "home/slider-logos/sap_bussi_one.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sap_bBD: file(relativePath: { eq: "home/slider-logos/sap_bBD.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sap_an_cloud: file(
      relativePath: { eq: "home/slider-logos/sap_an_cloud.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    riseWithSap: file(
      relativePath: { eq: "home/slider-logos/riseWithSap.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Sap_Products: file(relativePath: { eq: "home/Sap_Products.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    adobe: file(relativePath: { eq: "products/applications/adobe.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    corel_d: file(relativePath: { eq: "products/applications/corel_d.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    team_viewer: file(
      relativePath: { eq: "products/applications/team_viewer.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    arcserve: file(relativePath: { eq: "products/applications/arcserve.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    siemens: file(relativePath: { eq: "products/applications/siemens.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    hcl: file(relativePath: { eq: "products/applications/hcl.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    kapture: file(relativePath: { eq: "products/applications/kapture.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    proaxia: file(relativePath: { eq: "products/applications/proaxia.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tristha: file(relativePath: { eq: "products/applications/tristha.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Freshworks: file(
      relativePath: { eq: "products/applications/Freshworks.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    Diecentral: file(
      relativePath: { eq: "products/applications/Diecentral.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default Home
