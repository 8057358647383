import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
// import { getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import { Link } from "gatsby"
import SwiperCore, { Pagination } from "swiper"

SwiperCore.use([Pagination])

const ApplicationSoftware = props => {
  return (
    <Swiper
      slidesPerView={2}
      spaceBetween={100}
      slidesPerGroup={1}
      loop={true}
      loopFillGroupWithBlank={true}
      // pagination={{
      //   clickable: true,
      // }}
      navigation={{
        nextEl: ".swiper-button-next-software",
        prevEl: ".swiper-button-prev-software",
      }}
      className="mySwiper-software"
      breakpoints={{
        992: {
          maxWidth: 992,
          slidesPerView: 2,
        },
        768: {
          maxWidth: 768,
          slidesPerView: 1,
        },
        576: {
          maxWidth: 576,
          slidesPerView: 1,
        },
        0: {
          maxWidth: 0,
          slidesPerView: 1,
        },
      }}
    >
      {props.slidesRetailApp.map(slide => {
        return (
          <SwiperSlide className="mySwiper-r" key={slide.index}>
            <div className=" d-flex flex-column ">
              <span>
                <GatsbyImage
                  placeholder="blurred"
                  image={slide.img}
                  alt="techpac"
                  className="retail-img my-2"
                />
              </span>
              <p className="retail-application-slider-p">{slide.description}</p>
              <span>
                <Link href={slide.link}>
                  <div className="learn-more retail-learn-more">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                </Link>
              </span>
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
export default ApplicationSoftware
