import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import { Link } from "gatsby"
// import { getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
// Import Swiper React components
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

// install Swiper modules
SwiperCore.use([Navigation])

const CommonSlider = props => {
  return (
    <Swiper
      slidesPerView={props.sliderPerView}
      spaceBetween={0}
      slidesPerGroup={1}
      loop={true}
      loopFillGroupWithBlank={true}
      navigation={{
        nextEl: ".swiper-button-next1",
        prevEl: ".swiper-button-prev1",
      }}
      className="mySwiper-col"
      breakpoints={{
        1400: {
          maxWidth: 1400,
          slidesPerView: 4,
        },
        992: {
          maxWidth: 992,
          slidesPerView: 3,
        },
        768: {
          maxWidth: 768,
          slidesPerView: 2,
        },
        576: {
          maxWidth: 576,
          slidesPerView: 1,
        },
        0: {
          maxWidth: 0,
          slidesPerView: 1,
        },
      }}
    >
      {props.slides.map(slide => {
        return (
          <SwiperSlide key={slide.index}>
            <div className="col-one d-flex flex-column">
              {slide.img && (
                <span>
                  <GatsbyImage
                    placeholder="blurred"
                    image={slide.img}
                    alt="techpac"
                    className="img"
                  />
                </span>
              )}
              <span className={props.class}>{slide.title}</span>
              <p className="p-orange">{slide.description}</p>
              <span>
                <Link to={slide.link}>
                  <div className="learn-more">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                </Link>
              </span>
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
export default CommonSlider
