import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import { Link } from "gatsby"
// import { getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
// Import Swiper React components
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import ReactPlayer from "react-player"

// install Swiper modules
SwiperCore.use([Navigation])

const VideoSlider = props => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      slidesPerGroup={1}
      loop={true}
      loopFillGroupWithBlank={true}
      navigation={{
        nextEl: ".swiper-button-next-video",
        prevEl: ".swiper-button-prev-video",
      }}
      className="mySwiper-col-video"
    >
      {props.videos.map(video => {
        return (
          <SwiperSlide key={video.index}>
            <ReactPlayer controls={true} url={video.url} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
export default VideoSlider
