import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import BackgroundImage from "gatsby-background-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

import "swiper/swiper-bundle.css"
import { Link } from "gatsby"

// install Swiper modules
SwiperCore.use([Autoplay, Navigation])

const HomeLoopSlider = ({ slides }) => {
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={0}
      loop={true}
      autoplay={{
        delay: 4000,
      }}
      allowTouchMove={false}
      className="mySwiper"
    >
      {slides.map(slide => {
        const image = getImage(slide.image)
        const bgImage = convertToBgImage(image)
        return (
          <SwiperSlide key={slide.index}>
            <BackgroundImage {...bgImage} className="main-screen">
              <div className="main-screen-content container">
                <div className="main-screen-title d-flex flex-column">
                  {slide.titles.map((title, i) => (
                    <span key={i}>{title}</span>
                  ))}
                </div>
                <Link to={slide.link}>
                  <div className="learn-more">
                    LEARN MORE
                    <div className="icon">
                      <FontAwesomeIcon size="lg" icon={faPlayCircle} />
                    </div>
                  </div>
                </Link>
              </div>
            </BackgroundImage>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

HomeLoopSlider.propTypes = {
  slides: PropTypes.array,
}

export default HomeLoopSlider
